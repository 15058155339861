@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind variants; */

#app {
  @apply text-gray-900;
  @apply bg-white;
}

@media (min-width: 1440px) {
  html.app-gym {
    /* font-size: 15px; */
  }
}
